import { Controller } from 'stimulus';
import Offcanvas from 'bootstrap/js/src/offcanvas';

export default class extends Controller {
  static targets = ['remoteOffcanvas', 'frame', 'title'];

  connect() {
    if (this.hasRemoteOffcanvasTarget) {
      this.offcanvas = new Offcanvas(this.remoteOffcanvasTarget);

      this.clearTitleOnHidden();

      // Clear offcanvas content when hidden
      this.remoteOffcanvasTarget.addEventListener(
        'hidden.bs.offcanvas',
        () => (this.frameTarget.innerHTML = '')
      );
    }
  }

  clearTitleOnHidden() {
    this.remoteOffcanvasTarget.addEventListener(
      'hidden.bs.offcanvas',
      () => (this.titleTarget.textContent = '')
    );
  }

  disconnect() {
    if (this.offcanvas) {
      this.offcanvas.hide();
    }
  }

  showOffcanvas(event) {
    this.opener = event.currentTarget;

    // Set the offcanvas title from the opener attribute
    if (this.opener.hasAttribute('data-remote-offcanvas-title')) {
      this.titleTarget.textContent = this.opener.dataset.remoteOffcanvasTitle;
    }

    // Set the offcanvas class from the opener attribute...
    if (this.opener.hasAttribute('data-offcanvas-class')) {
      this.styleClass = this.opener.dataset.offcanvasClass;
      this.remoteOffcanvasTarget.classList.add(this.styleClass);

      // ...and remove it once when the offcanvas next closes
      this.remoteOffcanvasTarget.addEventListener(
        'hidden.bs.offcanvas',
        () => {
          this.remoteOffcanvasTarget.classList.remove(this.styleClass);
        },
        { once: true }
      );
    }

    this.offcanvas.show(event.target);
    this.frameTarget.innerHTML = '';
  }

  closeOffcanvas(event) {
    event.preventDefault();

    this.offcanvas.hide();

    // Forward the submit-success to the opener so other actions can be triggered
    if (event.type == 'turbo:submit-success') {
      const event = new CustomEvent('amba:remote-offcanvas.success', {
        bubbles: true,
        detail: { event },
      });
      this.opener.dispatchEvent(event);
    }
  }
}
