import { Controller } from 'stimulus';
import Chart from 'chart.js/auto';
import {
  chartBackground,
  currentTheme,
} from '../chart_controller/plugins/background_color_plugin';
import merge from 'lodash/merge';

const COLOR_BLACK = '#000';
const COLOR_GRAY = '#AAAFB5';
const COLOR_GRAY_LIGHT = '#E5E5E5';
const COLOR_GRAY_DARK = '#6A6A6A';

/**
 * Generates a bar chart with a legend.
 * Attach to a <canvas> element.
 */

export default class extends Controller {
  static values = {
    data: Object,
    options: Object,
    config: Object,
  };

  connect() {
    // When the theme is toggled, re-init the chart to get the correct colours
    document.addEventListener('amba:theme_changed', this.reInitOnColorChange);
    this.chartInit();
  }

  disconnect() {
    document.removeEventListener(
      'amba:theme_changed',
      this.reInitOnColorChange
    );
    this.chart.destroy();
  }

  chartInit() {
    this.chart = new Chart(this.element, {
      type: 'bar',
      plugins: this.plugins,
      data: this.dataValue,
      options: this.chartOptions,
    });
  }

  reInitOnColorChange = () => {
    this.chart.destroy();
    this.chartInit();
  };

  get chartOptions() {
    return merge(this.baseChartOptions, this.optionsValue);
  }

  get baseChartOptions() {
    return {
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: -10,
        },
      },
      plugins: {
        legend: {
          labels: {
            color: this.legendLabelColor,
            padding: 15,
          },
        },
      },
      scales: {
        x: {
          grid: {
            color: this.gridColor,
          },
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            color: this.chartLabelColor,
          },
        },
        y: {
          grid: {
            color: this.gridColor,
          },
          ticks: {
            color: this.chartLabelColor,
          },
          title: {
            color: this.chartLabelColor,
          },
        },
      },
    };
  }

  get plugins() {
    return [chartBackground(), this.legendPaddingPlugin];
  }

  // Chart.js doesn't allow creating more space between the legend and the chart,
  // however we need this to align it with other KI's.
  // This overrides the fit function to add some height to the overall canvas
  get legendPaddingPlugin() {
    return {
      beforeInit(chart) {
        const originalFit = chart.legend.fit;

        chart.legend.fit = function fit() {
          originalFit.bind(chart.legend)(); // Bind scope to use `this` correctly
          this.height += 5;
        };
      },
    };
  }

  get legendLabelColor() {
    return currentTheme() === 'light' ? COLOR_BLACK : COLOR_GRAY;
  }

  get chartLabelColor() {
    return currentTheme() === 'light' ? COLOR_GRAY_DARK : COLOR_GRAY;
  }

  get gridColor() {
    return currentTheme() === 'light' ? COLOR_GRAY_LIGHT : COLOR_GRAY_DARK;
  }
}
