import { Controller } from 'stimulus';

const LOCAL_STORAGE_KEY = 'rpm-session-time';

const CLASS_BELOW_TIMER_TARGET = 'text-warning';
const CLASS_ABOVE_TIMER_TARGET = 'text-success';

export default class extends Controller {
  static targets = ['displayTimer'];

  static values = {
    showTimer: Boolean,
    vipId: Number,
    recommendedTime: Number,
    recommendedTimeEnabled: Boolean,
    timeElapsed: Number,
    pauseTimer: Boolean,
  };

  connect() {
    if (this.pauseTimerValue) {
      return;
    }

    this.storage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

    if (this.showTimerValue) {
      this.setTimerColour();

      if (this.storage.vipId === this.vipIdValue) {
        if (this.storage.time) {
          this.timeElapsedValue = this.storage.time;
        }

        this.setupDisplayTimer();

        /**
         * The `disconnect` method will automatically handle turbo visits.
         *
         * This `beforeunload` event will catch all other page changes:
         *   - Hard refreshes
         *   - Hard page navigation
         *   - Closing the tab/browser
         */
        window.addEventListener(
          'beforeunload',
          () => {
            this.disconnect();
          },
          { once: true }
        );
      } else {
        this.resetTimer();
        this.setupDisplayTimer();
      }
    } else {
      this.resetTimer();
    }
  }

  disconnect() {
    if (this.currentTotal) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({
          time: this.currentTotal.total,
          vipId: this.vipIdValue,
        })
      );
    }

    this.timeElapsedValue = 0;
    this.currentDisplayTime = 0;
  }

  setupDisplayTimer() {
    this.currentDisplayTime = this.timeElapsedValue || 0;
    this.updateDisplayTimer();
    this.displayTimerInterval = setInterval(this.updateDisplayTimer, 1000);
  }

  updateDisplayTimer = () => {
    this.currentDisplayTime++;

    this.currentTotal = this.getTimeElapsed();
    this.displayTimerTarget.textContent = `${this.currentTotal.minutes}:${this.currentTotal.seconds}`;

    this.setTimerColour();
  };

  resetTimer() {
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({ time: 0, vipId: this.vipIdValue })
    );
  }

  getTimeElapsed() {
    return {
      total: this.currentDisplayTime,
      minutes: Math.floor((this.currentDisplayTime % 3600) / 60)
        .toString()
        .padStart(2, '0'),
      seconds: Math.floor(this.currentDisplayTime % 60)
        .toString()
        .padStart(2, '0'),
    };
  }

  setTimerColour() {
    if (this.recommendedTimeEnabledValue) {
      if (
        this.currentTotal?.total &&
        this.currentTotal.total >= this.recommendedTimeValue
      ) {
        this.displayTimerTarget.classList.remove(CLASS_BELOW_TIMER_TARGET);
        this.displayTimerTarget.classList.add(CLASS_ABOVE_TIMER_TARGET);
      } else {
        this.displayTimerTarget.classList.add(CLASS_BELOW_TIMER_TARGET);
      }
    }
  }
}
