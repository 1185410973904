const CLASS_HIDDEN = 'd-none';
import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = [
    'facility',
    'patient',
    'patientOuter',
    'facilityOuter',
    'observations',
    'enableProgressNotes',
    'firstName',
    'lastName',
  ];
  static values = {
    patientUrl: String,
    facilityUrl: String,
    currentFacility: Number,
    currentPatient: Number,
    vipId: Number,
  };

  async connect() {
    var messageSpan = document.createElement('span');
    messageSpan.setAttribute('id', 'pcc_vip_search_message');
    messageSpan.classList.add('form-text');

    this.patientOuterTarget.append(messageSpan);

    var facilityMessageSpan = document.createElement('span');
    facilityMessageSpan.setAttribute('id', 'pcc_facility_search_message');
    facilityMessageSpan.classList.add('form-text');

    this.facilityOuterTarget.append(facilityMessageSpan);

    var options = await this.getFacilityOptions();

    await this.setFacilityOptions(options);

    this.showOrHideObsList();
  }

  disconnect() {
    this.observationsTarget.classList.add(CLASS_HIDDEN);
    this.enableProgressNotesTarget.classList.add(CLASS_HIDDEN);
  }

  async showOrHideObsList() {
    if (this.facilityTarget.value && this.patientTarget.value) {
      this.observationsTarget.classList.remove(CLASS_HIDDEN);
      this.enableProgressNotesTarget.classList.remove(CLASS_HIDDEN);
    } else {
      this.observationsTarget.classList.add(CLASS_HIDDEN);
      this.enableProgressNotesTarget.classList.add(CLASS_HIDDEN);
    }
  }

  async setFacilityOptions(options) {
    for (const option of options) {
      var opt = document.createElement('option');
      opt.value = option['value'];
      opt.innerHTML = option['label'];
      if (option['value'] == this.currentFacilityValue) {
        opt.selected = 'selected';
      }
      this.facilityTarget.appendChild(opt);
    }
    if (this.facilityTarget.value) {
      await this.updatePatients();
    }
  }

  async setPatientOptions(options) {
    this.patientTarget.innerHTML = '';

    var options_list;

    if (options.length > 0) {
      options_list = options;
    } else {
      options_list = [{ value: '', label: ' ' }];
    }

    for (const option of options_list) {
      var opt = document.createElement('option');
      opt.value = option['value'];
      opt.innerHTML = option['label'];
      if (
        option['value'] == this.currentPatientValue ||
        options['value'] == ''
      ) {
        opt.selected = 'selected';
      }
      this.patientTarget.appendChild(opt);
    }
  }

  async getFacilityOptions() {
    const response = await fetch(this.facilityUrlValue, {
      method: 'POST',
      headers: this.headers,
    });

    var options;

    if (response.ok) {
      options = await response.json();
    } else {
      this.setFacilityFieldMessage(
        'Unable to fetch facilities. Please contact support'
      );
      window.appsignal.sendError(
        `Error retrieving vip search results: ${response.statusText}`
      );
      options = [];
    }

    return options;
  }

  async setPatientFieldMessage(message) {
    var span = document.getElementById('pcc_vip_search_message');
    span.innerText = message;
  }

  async setFacilityFieldMessage(message) {
    var span = document.getElementById('pcc_facility_search_message');
    span.innerText = message;
  }

  async patientOptions() {
    if (
      this.facilityTarget.value == '' ||
      this.firstNameValue == '' ||
      this.lastNameValue == ''
    ) {
      return [];
    }

    var patient_url = this.patientUrlValue;
    patient_url =
      patient_url +
      '?' +
      '&facility_id=' +
      this.facilityTarget.value +
      '&first_name=' +
      this.firstNameTarget.value +
      '&last_name=' +
      this.lastNameTarget.value +
      '&vip_id=' +
      this.vipIdValue;

    const response = await fetch(patient_url, {
      method: 'POST',
      headers: this.headers,
    });

    var options;

    if (response.ok) {
      options = await response.json();
    } else {
      this.setPatientFieldMessage(
        'Unable to fetch residents. Please contact support'
      );
      window.appsignal.sendError(
        `Error retrieving vip search results: ${response.statusText}`
      );
    }

    return options;
  }

  async updatePatients() {
    var options = await this.patientOptions();

    await this.setPatientOptions(options);
    this.showOrHideObsList();
    this.setPatientFieldMessage(
      `Found ${options.length} matching VIPs at this facility`
    );
  }

  get headers() {
    return {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
        ?.content,
      'Content-type': 'application/json; charset=UTF-8',
    };
  }
}
